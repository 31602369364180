<template>
  <!-- <ValidationObserver tag="form" ref="obs1" @submit.prevent="submit()"> -->
  <ValidationObserver
    tag="form"
    ref="didCampaignServiceRef"
    @submit.prevent="submit()"
    v-slot="{ invalid }"
  >
    <v-row class="px-5 mt-0">
      <v-col :cols="12">
        <h3 class="subtitle-2 font-weight-bold">
          <v-icon color="error" x-small>mdi-asterisk</v-icon>
          일정 선택
        </h3>
        <!-- 선택한 일정 목록 -->
        <ValidationProvider :rules="{ required: true }">
          <input hidden v-model="didCampaignService.scheduleDates" />
        </ValidationProvider>

        <!-- 선택한 셋톱박스 목록 -->
        <!-- <ValidationProvider :rules="{ required: true, min_value: 1 }">
          <input hidden v-model="selectedSettopBoxesCount" />
        </ValidationProvider> -->

        <template v-if="datepickerLoading">
          <v-skeleton-loader
            class="mb-0"
            type="list-item, card-heading, date-picker-days, date-picker-days"
          />
          <v-skeleton-loader class="mb-6" type="date-picker-days, actions" />
        </template>
        <v-date-picker
          v-show="!datepickerLoading"
          v-model="didCampaignService.scheduleDates"
          :allowed-dates="checkAllowedDates"
          :min="minDate"
          :max="maxDate"
          full-width
          no-title
          color="#00d1b2"
          multiple
          locale="ko-KR"
          class="custom-date-picker"
          @update:picker-date="fetchDidCampaignServiceDailySchedule"
        />
      </v-col>
      <!-- 샛톱박스 선택 위치 변경  -->
      <!-- <v-col :cols="12" md="6" class="pl-5">
        <h3 class="subtitle-2 font-weight-bold">
          <v-icon color="error" x-small>mdi-asterisk</v-icon>
          사용가능 셋톱 박스 ( 선택: {{ selectedSettopBoxesCount }} )
        </h3>
        <v-col
          :cols="12"
          style="max-height: 550px; min-height: 500px; overflow: auto"
          class="invalid_settop_box"
        >
          <v-expansion-panels
            v-model="panels"
            multiple
            @change="handleExpansionPannelChange"
          >
            <v-expansion-panel
              v-for="(item, index) in filteredDailySettopBoxes"
              :key="index"
              style="border: 1px solid gray"
            >
              <v-expansion-panel-header
                disable-icon-rotate
                dense
                style="background-color: #f2f2f2; min-height: auto"
                :class="'py-1'"
                @click.stop="e => (e.target.value = e.target.value ? 1 : 0)"
              >
                <v-col class="mb-0 py-2 pl-0 subtitle-2">
                  {{ item.key }}
                  <span class="ml-1 text-caption">
                    (
                    <span
                      :style="
                        item.list.selectedCount > 0 ? 'color: #00d1b2' : ''
                      "
                    >
                      {{ item.list.selectedCount }}
                    </span>
                    /
                    {{ item.list.options.length }})
                  </span>
                </v-col>

                <template v-slot:actions>
                  <v-chip
                    outlined
                    small
                    :color="item.list.isSelectAll ? '#00d1b2' : 'secondary'"
                    @click.stop="
                      () => (item.list.isSelectAll = !item.list.isSelectAll)
                    "
                  >
                    전체
                    {{ item.list.isSelectAll ? '해제' : '선택' }}
                  </v-chip>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                style="border-top: 1px solid #ccc"
                :class="'pa-0'"
              >
                <div>
                  <a-table
                    class="px-3 table-striped-rows"
                    :columns="columns"
                    :data-source="item.list.options"
                    size="small"
                    :locale="{
                      emptyText: '데이터가 없습니다.'
                    }"
                    bordered
                    rowKey="settopBoxId"
                    :row-selection="{
                      selectedRowKeys: item.list.selectedIds,
                      onChange: selected => onSelectChange(selected, index)
                    }"
                    :pagination="{ pageSize: 5 }"
                    :scroll="{ x: true }"
                  >
                    <template slot="settopBoxCostPerDay" slot-scope="value">
                      <div
                        class="text-right subtitle-body font-weight-600 red--text"
                      >
                        {{ (value * 1).toLocaleString() }}
                      </div>
                    </template>
                  </a-table>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row
            v-if="!dailySchedule.length"
            align="center"
            justify="center"
            class="pt-10"
          >
            <v-col cols="12" class="text-center">
              <v-icon> mdi-alert-outline </v-icon>
            </v-col>
            <h3>일정을 선택해주세요.</h3>
          </v-row>
        </v-col>
      </v-col> -->
    </v-row>
    <v-row>
      <DidCampaignServiceRegisterFooter :invalid="invalid" />
    </v-row>
  </ValidationObserver>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import DidCampaignServiceRegisterFooter from './DidCampaignServiceRegisterFooter.vue';
import { getDidCampaignDailyScheduleApi } from '@/api/admin/DidCampaignAPI';
import { SelectList } from '@/model/common';
import { DidCampaign } from '@/model/admin/did';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DidCampaignServiceRegisterFooter
  },
  computed: {
    ...mapGetters({
      currentStep: 'didCampaignService/currentStep',
      dialog: 'didCampaignService/dialog',
      loading: 'didCampaignService/loading',
      didCampaignService: 'didCampaignService/didCampaignService',
      campaignSteps: 'didCampaignService/didCampaignServiceSteps',
      invalidDialog: 'didCampaignService/invalidDialog'
    }),
    /** 선택한 셋톱박스 갯수 */
    selectedSettopBoxesCount() {
      let temp = 0;
      this.dailySettopBoxes.map(item => (temp += item.list.selectedCount));
      return temp;
    },
    /** 사용가능 셋톱 박스 날짜 목록 */
    filteredDailySettopBoxes() {
      const { scheduleDates } = this.didCampaignService;

      if (!scheduleDates) return [];

      return this.dailySettopBoxes.filter(item =>
        scheduleDates.includes(item.key)
      );
    },
    // 사용가능 셋톱박스 테이블 columns
    columns() {
      return [
        {
          title: '이름',
          dataIndex: 'settopBoxName',
          key: 'settopBoxName',
          align: 'center',
          sorter: (a, b) => a.settopBoxName.localeCompare(b.settopBoxName)
        },
        {
          title: '지점 이름',
          dataIndex: 'didBranchName',
          key: 'didBranchName',
          align: 'center',
          sorter: (a, b) => a.didBranchName.localeCompare(b.didBranchName)
        },
        {
          title: '잔여 수량 (개)',
          dataIndex: 'redundancy',
          key: 'redundancy',
          align: 'center'
        },
        {
          title: '금액 (원)',
          dataIndex: 'settopBoxCostPerDay',
          key: 'settopBoxCostPerDay',
          align: 'center',
          scopedSlots: {
            customRender: 'settopBoxCostPerDay'
          }
        }
      ];
    }
  },
  watch: {
    currentStep: function (newVal, prevVal) {
      if (newVal === 2 && prevVal < newVal) {
        this.init();
      }
    },
    /** didCampaigns 형태 */
    selectedSettopBoxesCount: function () {
      let temp = [];
      this.filteredDailySettopBoxes.map(item => {
        temp = [...temp, ...item.list.selected];
      });
      this.didCampaignService.didCampaigns = [...temp];
    },
    /** 날짜 선택시 */
    // 'didCampaignService.scheduleDates': function (newVal, prevVal) {
    //   // 선택해제 했을 경우
    //   console.log('newVal', newVal);
    //   console.log('prevVal', prevVal);
    // }
    'didCampaignService.scheduleDates': function (newVal, prevVal) {
      // 선택해제 했을 경우
      if (newVal.length < prevVal.length) {
        const diffDate = prevVal.filter(x => !newVal.includes(x)); // 선택 해제한 값

        const dailySettopBoxe = this.dailySettopBoxes.find(
          item => item.key === diffDate[0]
        );

        dailySettopBoxe.list.selectedIds = []; // 선택한 정보 제거
        dailySettopBoxe.list.isOpen = false; // expend 닫기
      }

      if (newVal.length > prevVal.length) {
        const diffDate = newVal.filter(x => !prevVal.includes(x)); // 선택 값

        const dailySettopBoxe = this.dailySettopBoxes.find(
          item => item.key === diffDate[0]
        );

        dailySettopBoxe.list.isOpen = true; // expend 닫기
      }

      // 선택된 날짜의 선택한 settopbox
      let temp = [];
      this.filteredDailySettopBoxes.map(item => {
        // temp = [...temp, ...item.list.options];
        temp = [...temp, ...item.list.options];

        this.didCampaignService.didCampaigns = [...temp];
      });
    }
  },
  data() {
    return {
      searchMoths: [], // 조회한 달
      dailySchedule: [], // 선택 가능한 날짜
      dailySettopBoxes: [], // 일별 셋톱박스목록
      panels: [],

      // minDate: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'), // 최소 선택 가능일
      minDate: this.$moment().format('YYYY-MM-DD'), // 최소 선택 가능일
      maxDate: null, // 최대 선택 가능일
      settopBoxPannels: 0,
      datepickerLoading: false
    };
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'didCampaignService/setCurrentStep'
    }),
    async init() {
      this.dailySettopBoxes = [];
      this.searchMoths = [];
      this.dailySchedule = [];
      await this.fetchDidCampaignServiceDailySchedule();
    },
    /** 날짜 선택 가능여부 체크 */
    checkAllowedDates(val) {
      return this.dailySchedule.includes(val);
    },
    /** 사용 가능일 & 셋톱박스 목록 조회 */
    async fetchDidCampaignServiceDailySchedule(month = null) {
      if (!this.didCampaignService.didBranchIds.length) return;
      // 조회 시작일, 조회 종료일
      const params = {
        startDate: month
          ? this.$moment(month).startOf('month').format('YYYY-MM-DD')
          : this.minDate,
        endDate: this.$moment(month ?? this.minDate)
          .endOf('month')
          .format('YYYY-MM-DD'),
        branches: this.didCampaignService.didBranchIds,
        settopBoxes: this.didCampaignService.didSettopBoxIds
      };
      // console.log(params, month);

      const searchMonth = this.$moment(params.startDate)
        .endOf('month')
        .format('YYYY-MM');

      // 이미 조회한 달이면 패스
      if (this.searchMoths.includes(searchMonth)) return;

      this.datepickerLoading = true;

      const [error, data] = await getDidCampaignDailyScheduleApi({
        params: params
      });

      if (error) {
        console.error(error);
        this.datepickerLoading = false;
      } else {
        const { schedule } = data;
        const tempSchedule = Object.keys(schedule).filter(
          item => schedule[item].length > 0
        );
        // 예약 가능한 스케줄 목록
        this.dailySchedule = [...this.dailySchedule, ...tempSchedule];

        for (const key in schedule) {
          const data = schedule[key];

          this.dailySettopBoxes.push({
            key: key,
            list: new SelectList({
              key: key,
              selectedKey: 'settopBoxId',
              options: data.map(
                item =>
                  new DidCampaign(
                    {
                      scheduleDate: key,
                      settopBoxId: item.id,
                      settopBox: item,
                      didBranch: item.branch,
                      didBranchId: item.branch?.id,
                      redundancy: item.redundancy
                    },
                    true
                  )
              )
            })
          });
        }
        this.datepickerLoading = false;
        this.searchMoths.push(searchMonth);
      }
    },
    async submit() {
      const valid = await this.$refs.didCampaignServiceRef.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    },
    openInNewTab(landingUrl) {
      window.open(landingUrl, '_blank');
    },
    /**@description: 날짜별 셋톱박스 선택했을 경우 */
    onSelectChange(selectedRowKeys, index) {
      // 사용가능 셋톱박스 목록에 있는 index 로 선택한 ID값 세팅
      this.filteredDailySettopBoxes[index].list.selectedIds = [
        ...selectedRowKeys
      ];
    },
    // pannel 변경시
    handleExpansionPannelChange(newVal) {
      // pannel 자체 open 값을 설정
      this.filteredDailySettopBoxes.map((item, index) => {
        item.list.isOpen = newVal.includes(index) ? true : false;
      });
    }
  }
};
</script>
<style lang="scss">
.custom-date-picker {
  .v-date-picker-table {
    height: auto !important;
  }
  td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
.invalid_settop_box {
  .v-expansion-panel-content__wrap {
    padding: 15px 0 0;
  }
}
</style>
